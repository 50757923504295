import Judges from "./Judges";

function Mission() {
  return (
    <div className="Mission">
      <main>
        <h2 style={{marginTop: '120px'}}>Meet the Team</h2>
        <Judges />
      </main>
      <footer>©2023 DI@Tech</footer>
    </div>
  );
}

export default Mission;
